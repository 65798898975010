import { useContext, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { scroller } from 'react-scroll'
import { SHOW as CAUTIONSHOW } from 'reducer/survey/live/caution'
import { SHOW as BACKDROPSHOW, HIDE as BACKDROPHIDE } from 'reducer/backdrop'
import { SHOW as ERRORPAGESHOW } from 'reducer/errorPage'
import PropsContext from 'context/props'
import AccessMethodContext from 'context/accessMethod'
import { saveComment } from 'utils/survey'
import { memoryReply } from 'container/Survey/Live/Container'
import { checkRequireAnswered } from 'container/Survey/Live/One'
import { showFileUploadLoading } from 'container/Survey/Live/Multi'
import { getSignedUrl, uploadFile, deleteFile } from 'component/Survey/Modules/19/S3'
import { SurveyLivePageComponent } from 'component'

const osaveComment = new saveComment()

let saveDo = true

export class VerifyFileUpload {
    constructor(props) {
        const { mode, idd, survey_no, fileuploadLoadingId, dispatch, f, copy, initData } = props
        this.mode = mode
        this.idd = idd
        this.survey_no = survey_no
        this.fileuploadLoadingId = fileuploadLoadingId
        this.dispatch = dispatch
        this.f = f
        this.copy = copy
        this.initData = initData
    }

    // mode가 라이브면 null로 리턴 preview면 blob으로 넘긴다
    getFileBlob(mode, file) {  
        if (mode === 'live') return null
        return URL.createObjectURL(Object.assign(file))
    }

    // 파일업로드 중 업로드 해야할 파일만 빼낸다.
    getCopyFile() {
        return this.copy.filter(c => {
            const { module_type } = c
            if (module_type !== '_19') return false

            if (typeof c.src === 'object') return true

            return false
        })
    }

    async upload(files) {
        // 업로드 된 src 담는다
        let srcs = {}

        // 파일길이
        const filesLen = files.length
        const uploadFiles = []
        for (let i=0; i<filesLen; i++) {
            const item = files[i]

            const { survey_question_no, src } = item

            const blob = this.getFileBlob(this.mode, src)
    
            if (blob) {
                srcs = {...srcs, [survey_question_no]: { location: blob, filesize: 1000 } }
                continue
            }

            uploadFiles.push(item)
        }

        const uploadFilesLen = uploadFiles.length

        if (uploadFilesLen === 1) {
            showFileUploadLoading(this.fileuploadLoadingId, 'flex', this.f({id: 'component.Survey.Live.PageMulti.VerifyFileUpload.message.one'}))
        } else if (uploadFilesLen > 1) {
            showFileUploadLoading(this.fileuploadLoadingId, 'flex', this.f({id: 'component.Survey.Live.PageMulti.VerifyFileUpload.message.multi'}, {index: 1, len: uploadFilesLen}))
        }

        let signedurl = []
        if (uploadFilesLen > 0) {
            const datas = uploadFiles.map(c => ({
                filename: c.filename,
                survey_question_no: c.survey_question_no
            }))

            try {
                signedurl = await getSignedUrl({ method: 'put', mode: this.mode, survey_no: this.survey_no, data: datas })
            } catch(e) {
                throw e
            }
        }

        // 파일 업로드 문항있을경우 업로드 한다.
        let error = false
        for (let i=0; i<uploadFilesLen; i++) {
            const item = uploadFiles[i]

            const { survey_question_no, src } = item
            
            if (uploadFilesLen > 1 && i > 0) {
                showFileUploadLoading(this.fileuploadLoadingId, 'flex', this.f({id: 'component.Survey.Live.PageMulti.VerifyFileUpload.message.multi'}, {index: (i+1), len: uploadFilesLen}))
            }
            
            const res = await uploadFile(signedurl[i], src)

            if (res.status) {
                srcs = {...srcs, [survey_question_no]: { location: res.location, filesize: res.filesize } }
            
            } else {
                this.dispatch({ type: BACKDROPHIDE })
                error = true
                return
            }   
        }

        if (error) return false
 
        // 업로드 된 src를 copy에 담는다
        if (Object.values(srcs).length > 0) {
            this.copy = this.copy.map(c => {
                const { survey_question_no, filename } = c
                
                const is = srcs[survey_question_no]
                if (!is) return c

                let newFilename = filename
                
                if (newFilename.length > 100) {
                    const extArray = filename.split('.')
                    const ext = extArray.length > 1 ? `.${extArray.pop()}` : ''

                    newFilename = `${filename.substr(0, (100 - ext.length))}${ext}`
                }

                const { location, filesize } = is

                return {
                    ...c,
                    src: location,
                    filename: newFilename,
                    filesize
                }
            })
        }

        return true

    }

    async del(survey_question_nos) {
        const srcs = []

        // 파일첨부은 기존 저장된 데이터랑 현재 값이랑 다르다면 기존 데이터 aws에서 삭제한다.
        for (let i=0; i<this.initData.length; i++) {
            const item = this.initData[i]

            if (item.__typename !== 'Module_19') continue

            if (!survey_question_nos.includes(item.survey_question_no)) continue


            const { survey_question_no, src } = item

            const isCopy = this.copy.find(c => c.survey_question_no === survey_question_no)
     
            if (src && !isCopy) {
                srcs.push(src)
            } else if (src && isCopy) {
                if (src !== isCopy.src) {
                    srcs.push(src)
                }
            }
        }

        if (srcs.length > 0) {
            await deleteFile({ mode: this.mode, survey_no: this.survey_no, srcs })
        }

        return true
    }

    async do(survey_question_nos) {
        const files = this.getCopyFile()
        const res = await this.upload(files)

        showFileUploadLoading(this.fileuploadLoadingId, 'none', '')

        // 업로드 실패 했다면..
        if (!res) return false

        await this.del(survey_question_nos)
        
        return this.copy
    }
}
 
export default (props) => {
    const { isClassicButton } = props

    const accessMethod = useContext(AccessMethodContext)
    const { 
        initData,
        survey_no,
        mbox,
        fileuploadLoadingId,
        questions, 
        pages, 
        page, 
        startUsed, 
        refComments01,
        refComments02,
        refComments09,
        refComments10,
        refComments12,
        refComments13,
        refComments17,
        refComments18,
        refComments20,
        refCommentsWith,
        required,
        scrollerObj,
        onNext, 
        onPrev
    } = useContext(PropsContext)

    const { idd } = mbox

    const dispatch = useDispatch()

    const { formatMessage: f } = useIntl()
    
    const confirm09 = useSelector(state => state.surveyLiveConfirm09)
    
    // 페이지 길이
    const steps = pages.length

    // 페이지 번호는 0부터 ..
    let activeStep = pages.filter(c => c.survey_page_no === page).reduce((acc, c) => c.indexs - 1, '')
    
    activeStep = activeStep ? activeStep : 0


    // 저장하기전에 필수 응답 체크
    const handleCheck = useCallback(async(newPage) => {
        if (!saveDo) return

        saveDo = false
        // 점프로직이 생기면서 문항이 보이고 안보이는 경우가 생겻다 점프있는 보기 체크 안한상태에서 체크한값이 사용자가 점프있느 보기로 체크를 변경한후 저장할때
        // 점프로인해 안보이는값 그전에 체크된값은 저장하면안된다
        const survey_question_nos = questions.map(c => c._question.survey_question_no)
        
        // ref 값을 state에 저장한다
        let copy = [...memoryReply.rows.filter(c => survey_question_nos.includes(c.survey_question_no))]
        
        const refComments = {
            refComments01,
            refComments02,
            refComments09,
            refComments10,
            refComments12,
            refComments13,
            refComments17,
            refComments18,
            refComments20
        }
        // 보기 코멘트 값 저장
        const data = osaveComment.saveAnswerComment({
            questions, 
            rows: copy, 
            refComments
        })

        const comments = osaveComment.saveQuestionComment({
            questions, 
            refCommentsWith
        })

        const requiredValue = await checkRequireAnswered({
            questions, 
            replys: data, 
            comments, 
            passComment: false,
            confirm09Rows: confirm09.rows
        }) 

        if (required) {
            if (requiredValue[0] === false) {

                //await onChangeReplys(memoryReply.rows)

                await dispatch({ type: CAUTIONSHOW, survey_question_no: requiredValue[1], code: requiredValue[2], message: requiredValue[3] })

                scroller.scrollTo(scrollerObj.target, {
                    ...scrollerObj.scrollTo,
                    containerId: scrollerObj.id
                })

                saveDo = true
                return
            }
        }

        const verifyFileUpload = new VerifyFileUpload({ mode: accessMethod.mode, idd, survey_no, fileuploadLoadingId, dispatch, f, copy: data, initData })
        
        const customCopy = await verifyFileUpload.do(questions.map(c => c._question.survey_question_no))

        if (!customCopy) {
            window.alert(f({id: 'component.Survey.Live.PageMulti.VerifyFileUpload.message.error'}))
            saveDo = true
            return
        }

        dispatch({ type: BACKDROPSHOW })
        onNext(newPage, customCopy, comments)
        .then(() => {
            setTimeout(() => {
                saveDo = true
            }, 1000)
        })
        .catch(() => {
            dispatch({ type: ERRORPAGESHOW })
        })
    }, [
        initData,
        survey_no,
        idd,
        fileuploadLoadingId,
        accessMethod.mode,
        questions, 
        confirm09.rows,
        dispatch,
        f,
        refComments01,
        refComments02,
        refComments09,
        refComments10,
        refComments12,
        refComments13,
        refComments17,
        refComments18,
        refComments20,
        refCommentsWith,
        required,
        scrollerObj,
        onNext
    ])

    return (
        <SurveyLivePageComponent
            isClassicButton={isClassicButton}
            type="multi"
            steps={steps} 
            activeStep={activeStep} 
            startUsed={startUsed}
            onNext={handleCheck}
            onBack={onPrev}
        />
    )
}
